import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faSpinner,
  faBank,
  faInfoCircle,
  faTrash,
  faCheckCircle,
  faHeart,
  faEdit,
  faUpload,
  faWallet,
  faExclamationTriangle,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const Section = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  color: #377e62;
  margin-bottom: 20px;
`;

const Input = styled.input`
  width: 95%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const TextArea = styled.textarea`
  width: 95%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  min-height: 100px;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #377e62;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 8px;

  &:disabled {
    background-color: #cccccc;
  }
`;

const ErrorMessage = styled.div`
  color: #f44336;
  background: #ffebee;
  padding: 10px;
  border-radius: 4px;
  margin: 10px 0;
`;

const SuccessMessage = styled.div`
  color: #4caf50;
  background: #e8f5e9;
  padding: 10px;
  border-radius: 4px;
  margin: 10px 0;
`;

const TotalProgress = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 20px;
  background: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px 0;
`;

const Progress = styled.div`
  width: ${(props) => props.$percentage}%;
  height: 100%;
  background-color: #377e62;
  transition: width 0.3s ease;
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
`;

const CampaignForm = ({ onCreate, walletBalance, setError }) => {
  const [campaign, setCampaign] = useState({
    title: "",
    description: "",
    targetAmount: "",
    duration: "", // Added duration field
    benefits: [""], // Initialize with one empty benefit
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleBenefitChange = (index, value) => {
    const newBenefits = [...campaign.benefits];
    newBenefits[index] = value;
    setCampaign({ ...campaign, benefits: newBenefits });
  };

  const addBenefit = () => {
    setCampaign({ ...campaign, benefits: [...campaign.benefits, ""] });
  };

  const removeBenefit = (index) => {
    const newBenefits = campaign.benefits.filter((_, i) => i !== index);
    setCampaign({ ...campaign, benefits: newBenefits });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(""); // Clear any existing errors

    try {
      // Validate inputs
      if (!campaign.title.trim()) {
        throw new Error("Campaign title is required");
      }
      if (!campaign.description.trim()) {
        throw new Error("Campaign description is required");
      }
      if (!campaign.targetAmount || parseFloat(campaign.targetAmount) <= 0) {
        throw new Error("Please enter a valid target amount");
      }
      if (
        !campaign.duration ||
        parseInt(campaign.duration) <= 0 ||
        parseInt(campaign.duration) > 90
      ) {
        throw new Error("Please enter a valid duration between 1 and 90 days");
      }

      const targetAmount = parseFloat(campaign.targetAmount);
      const platformFeeRM = targetAmount * 0.05; // 5% in RM
      const platformFeeBoons = Math.round(platformFeeRM * 100); // Convert to Boons (1 RM = 100 Boons)

      // Log values for debugging
      console.log("Campaign Creation Data:", {
        campaignDetails: campaign,
        walletBalance,
        platformFeeBoons,
        platformFeeRM,
      });

      if (platformFeeBoons > walletBalance * 100) {
        throw new Error(
          `Insufficient funds for platform fee (${platformFeeBoons} Boons / RM${platformFeeRM.toFixed(
            2
          )}). Please top up your wallet to continue.`
        );
      }

      // First check bank details
      try {
        const bankDetailsResponse = await axios.get("/api/user/bank-details", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        });
        console.log("Bank details:", bankDetailsResponse.data);

        if (
          !bankDetailsResponse.data ||
          !bankDetailsResponse.data.bankName?.trim() ||
          !bankDetailsResponse.data.accountNumber?.trim() ||
          !bankDetailsResponse.data.accountHolder?.trim()
        ) {
          throw new Error(
            "Please complete your bank details in your profile before creating a campaign"
          );
        }
      } catch (err) {
        throw new Error(
          "Please add your bank details in your profile before creating a campaign"
        );
      }

      // Filter out empty benefits
      const filteredBenefits = campaign.benefits.filter(
        (benefit) => benefit.trim() !== ""
      );

      // Prepare campaign data
      const campaignData = {
        title: campaign.title.trim(),
        description: campaign.description.trim(),
        targetAmount: targetAmount,
        benefits: filteredBenefits,
        duration: parseInt(campaign.duration), // Add this line
      };

      console.log("Sending campaign data:", campaignData);

      const response = await axios.post("/api/campaigns", campaignData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          "Content-Type": "application/json",
        },
      });

      console.log("Campaign creation response:", response.data);

      onCreate();
      setCampaign({
        title: "",
        description: "",
        targetAmount: "",
        duration: "",
        benefits: [""],
      });
    } catch (err) {
      console.error("Campaign creation error:", err);
      const errorMessage =
        err.response?.data?.message ||
        err.message ||
        "Failed to create campaign";
      console.error("Error details:", {
        status: err.response?.status,
        data: err.response?.data,
        message: errorMessage,
      });
      setError(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Section>
      <Title>Create New Campaign</Title>
      <form onSubmit={handleSubmit}>
        <Input
          placeholder="Campaign Title"
          value={campaign.title}
          onChange={(e) => setCampaign({ ...campaign, title: e.target.value })}
          required
        />
        <TextArea
          placeholder="Campaign Description"
          value={campaign.description}
          onChange={(e) =>
            setCampaign({ ...campaign, description: e.target.value })
          }
          required
        />
        <Input
          type="number"
          placeholder="Target Amount (RM)"
          value={campaign.targetAmount}
          onChange={(e) =>
            setCampaign({ ...campaign, targetAmount: e.target.value })
          }
          required
          min="1"
          step="0.01"
        />
        <Input
          type="number"
          placeholder="Campaign Duration (days)"
          value={campaign.duration}
          onChange={(e) =>
            setCampaign({ ...campaign, duration: e.target.value })
          }
          required
          min="1"
          max="90"
        />

        <div style={{ marginBottom: "15px" }}>
          <h4 style={{ color: "#377e62", marginBottom: "10px" }}>
            Funder Benefits
          </h4>
          {campaign.benefits.map((benefit, index) => (
            <div
              key={index}
              style={{ display: "flex", gap: "10px", marginBottom: "10px" }}
            >
              <Input
                value={benefit}
                onChange={(e) => handleBenefitChange(index, e.target.value)}
                placeholder={`Benefit ${index + 1}`}
                style={{ marginBottom: 0 }}
                required={index === 0} // First benefit is required
              />
              {campaign.benefits.length > 1 && (
                <button
                  type="button"
                  onClick={() => removeBenefit(index)}
                  style={{
                    background: "#f44336",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    padding: "0 10px",
                    cursor: "pointer",
                  }}
                >
                  ×
                </button>
              )}
            </div>
          ))}
          <Button
            type="button"
            onClick={addBenefit}
            style={{ backgroundColor: "#4caf50", marginTop: "10px" }}
          >
            + Add Benefit
          </Button>
        </div>

        {/* Wallet Balance Display */}
        <div
          style={{
            marginBottom: "15px",
            color: "#666",
            backgroundColor: "#f5f5f5",
            padding: "10px",
            borderRadius: "4px",
          }}
        >
          <FontAwesomeIcon icon={faWallet} /> Current Wallet Balance:{" "}
          {(walletBalance * 100).toLocaleString()} Boons (RM
          {walletBalance.toFixed(2)})
        </div>

        {/* Platform Fee Calculation */}
        {campaign.targetAmount && (
          <div
            style={{
              marginBottom: "15px",
              backgroundColor: "#e8f5e9",
              padding: "10px",
              borderRadius: "4px",
              border: "1px solid #81c784",
            }}
          >
            <div style={{ color: "#2e7d32", marginBottom: "5px" }}>
              <FontAwesomeIcon icon={faInfoCircle} /> Platform Fee (5%):
            </div>
            <div style={{ color: "#1b5e20" }}>
              {Math.round(
                parseFloat(campaign.targetAmount) * 0.05 * 100
              ).toLocaleString()}{" "}
              Boons / RM{(parseFloat(campaign.targetAmount) * 0.05).toFixed(2)}
            </div>
            {Math.round(parseFloat(campaign.targetAmount) * 0.05 * 100) >
              walletBalance * 100 && (
              <div
                style={{
                  color: "#d32f2f",
                  marginTop: "5px",
                  fontSize: "0.9em",
                }}
              >
                <FontAwesomeIcon icon={faExclamationTriangle} /> Insufficient
                balance for platform fee
              </div>
            )}
          </div>
        )}

        {/* Create Button */}
        <Button
          type="submit"
          disabled={
            isSubmitting ||
            (campaign.targetAmount &&
              Math.round(parseFloat(campaign.targetAmount) * 0.05 * 100) >
                walletBalance * 100)
          }
          style={{
            width: "100%",
            opacity:
              isSubmitting ||
              (campaign.targetAmount &&
                Math.round(parseFloat(campaign.targetAmount) * 0.05 * 100) >
                  walletBalance * 100)
                ? 0.7
                : 1,
          }}
        >
          {isSubmitting ? (
            <>
              <FontAwesomeIcon icon={faSpinner} spin /> Creating...
            </>
          ) : campaign.targetAmount &&
            Math.round(parseFloat(campaign.targetAmount) * 0.05 * 100) >
              walletBalance * 100 ? (
            <>
              <FontAwesomeIcon icon={faExclamationTriangle} /> Insufficient
              Balance
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faPlus} /> Create Campaign
            </>
          )}
        </Button>
      </form>
    </Section>
  );
};

const DonatorsList = ({ donations }) => {
  if (!donations || donations.length === 0) {
    return <p>No donations yet.</p>;
  }

  return (
    <div style={{ marginTop: "15px" }}>
      <h4>Supporters:</h4>
      <div style={{ maxHeight: "200px", overflowY: "auto" }}>
        {donations.map((donation, index) => (
          <div
            key={index}
            style={{
              padding: "8px",
              borderBottom: "1px solid #eee",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>{donation.email}</span>
            <span>RM{donation.amount.toFixed(2)}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

const CampaignList = ({ campaigns, isOwner, onDelete, onDonate }) => {
  const navigate = useNavigate();
  const [showClaimModal, setShowClaimModal] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [claimAmount, setClaimAmount] = useState("");
  const [claimComment, setClaimComment] = useState("");
  const [claimFile, setClaimFile] = useState(null);
  const [claimError, setClaimError] = useState("");
  const [claimSuccess, setClaimSuccess] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showCloseConfirmModal, setShowCloseConfirmModal] = useState(false);

  const isExpired = (campaign) => {
    const createdDate = new Date(campaign.createdAt);
    const endDate = new Date(
      createdDate.getTime() + campaign.duration * 24 * 60 * 60 * 1000
    );
    return new Date() > endDate;
  };

  // Sort campaigns: active first (newest to oldest), then completed (newest to oldest)
  const sortedCampaigns = [...campaigns].sort((a, b) => {
    if (a.status === b.status) {
      return new Date(b.createdAt) - new Date(a.createdAt);
    }
    return a.status === "active" ? -1 : 1;
  });

  const handleDonateClick = (campaign) => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      if (
        window.confirm(
          "Please log in or sign up to support this campaign. Would you like to sign up now?"
        )
      ) {
        navigate("/signup");
      }
      return;
    }

    // Check if campaign is closed or completed
    if (campaign.status === "completed") {
      alert(
        "This campaign is already completed and cannot receive more donations."
      );
      return;
    }

    // Check if campaign has expired
    const createdDate = new Date(campaign.createdAt);
    const endDate = new Date(
      createdDate.getTime() + campaign.duration * 24 * 60 * 60 * 1000
    );
    if (new Date() > endDate) {
      alert("This campaign has ended and cannot receive more donations.");
      return;
    }

    onDonate(campaign);
  };

  const handleClaimSubmit = async () => {
    if (!selectedCampaign) return;

    setIsSubmitting(true);
    setClaimError("");
    setClaimSuccess("");

    try {
      if (!claimAmount || parseFloat(claimAmount) <= 0) {
        throw new Error("Please enter a valid claim amount");
      }

      if (claimComment.length > 150) {
        throw new Error("Comment must be less than 150 characters");
      }

      const formData = new FormData();
      formData.append("amount", claimAmount);
      formData.append("comment", claimComment);

      if (claimFile) {
        formData.append("file", claimFile);
      }

      await axios.post(
        `/api/campaigns/${selectedCampaign._id}/claims`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setClaimSuccess("Claim submitted successfully");
      setTimeout(() => {
        setShowClaimModal(false);
        setClaimAmount("");
        setClaimComment("");
        setClaimFile(null);
        window.location.reload(); // Refresh the page to show updated claims
      }, 2000);
    } catch (error) {
      console.error("Error submitting claim:", error);
      setClaimError(error.response?.data?.message || error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCloseCampaign = async () => {
    if (!selectedCampaign) return;

    setIsSubmitting(true);
    setClaimError("");

    try {
      await axios.post(
        `/api/campaigns/${selectedCampaign._id}/close`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );

      setClaimSuccess("Campaign closed successfully");
      setTimeout(() => {
        setShowCloseConfirmModal(false);
        window.location.reload(); // Refresh the page to show updated status
      }, 2000);
    } catch (error) {
      console.error("Error closing campaign:", error);
      setClaimError(error.response?.data?.message || error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const canCloseCampaign = (campaign) => {
    if (!campaign || campaign.status !== "active") return false;

    const totalClaimed = campaign.totalClaimed || 0;
    return totalClaimed >= campaign.currentAmount * 0.8;
  };

  const getClaimedPercentage = (campaign) => {
    if (!campaign || campaign.currentAmount === 0) return 0;
    const totalClaimed = campaign.totalClaimed || 0;
    return (totalClaimed / campaign.currentAmount) * 100;
  };

  return (
    <Section>
      <Title>Campaigns</Title>

      {/* Total Progress Bar */}
      {campaigns.length > 0 && (
        <TotalProgress>
          <h3 style={{ marginBottom: "10px" }}>Overall Campaign Progress</h3>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "5px",
            }}
          >
            <span>
              Total Raised: RM
              {campaigns
                .reduce((acc, c) => acc + c.currentAmount, 0)
                .toFixed(2)}
            </span>
            <span>
              Total Target: RM
              {campaigns.reduce((acc, c) => acc + c.targetAmount, 0).toFixed(2)}
            </span>
          </div>
          <ProgressBar>
            <Progress
              $percentage={
                (campaigns.reduce((acc, c) => acc + c.currentAmount, 0) /
                  campaigns.reduce((acc, c) => acc + c.targetAmount, 0)) *
                100
              }
            />
          </ProgressBar>
        </TotalProgress>
      )}

      {sortedCampaigns.map((campaign) => (
        <div
          key={campaign._id}
          style={{
            marginBottom: "20px",
            padding: "15px",
            border: "1px solid #ddd",
            borderRadius: "4px",
            backgroundColor:
              campaign.status === "completed" ? "#f5f5f5" : "white",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3>{campaign.title}</h3>
            {campaign.status === "completed" && (
              <span
                style={{
                  backgroundColor: "#4caf50",
                  color: "white",
                  padding: "4px 8px",
                  borderRadius: "4px",
                  fontSize: "12px",
                }}
              >
                Completed
              </span>
            )}
          </div>

          <p>{campaign.description}</p>

          {/* Duration Display */}
          <div
            style={{
              marginTop: "10px",
              backgroundColor: "#f8f9fa",
              padding: "10px",
              borderRadius: "4px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>
                <FontAwesomeIcon
                  icon={faClock}
                  style={{ marginRight: "8px" }}
                />
                Duration: {campaign.duration} days
              </span>
              {campaign.status === "active" && (
                <span
                  style={{
                    backgroundColor: "#e3f2fd",
                    padding: "4px 8px",
                    borderRadius: "4px",
                    fontSize: "0.9em",
                    color: "#1976d2",
                  }}
                >
                  {(() => {
                    const createdDate = new Date(campaign.createdAt);
                    const endDate = new Date(
                      createdDate.getTime() +
                        campaign.duration * 24 * 60 * 60 * 1000
                    );
                    const now = new Date();
                    const timeLeft = endDate - now;

                    if (timeLeft <= 0) return "Campaign has ended";

                    const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
                    const hours = Math.floor(
                      (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
                    );
                    const minutes = Math.floor(
                      (timeLeft % (1000 * 60 * 60)) / (1000 * 60)
                    );

                    if (days > 0) {
                      return `Campaign ending in ${days} day${
                        days > 1 ? "s" : ""
                      } ${hours} hour${hours > 1 ? "s" : ""}`;
                    } else if (hours > 0) {
                      return `Campaign ending in ${hours} hour${
                        hours > 1 ? "s" : ""
                      } ${minutes} minute${minutes > 1 ? "s" : ""}`;
                    } else {
                      return `Campaign ending in ${minutes} minute${
                        minutes > 1 ? "s" : ""
                      }`;
                    }
                  })()}
                </span>
              )}
            </div>
          </div>

          {campaign.benefits && campaign.benefits.length > 0 && (
            <div style={{ marginTop: "10px" }}>
              <h4 style={{ color: "#377e62", marginBottom: "5px" }}>
                Funder Benefits:
              </h4>
              <ul style={{ marginLeft: "20px" }}>
                {campaign.benefits.map((benefit, index) => (
                  <li key={index}>{benefit}</li>
                ))}
              </ul>
            </div>
          )}

          <div style={{ marginTop: "15px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "5px",
              }}
            >
              <span>Raised: RM{campaign.currentAmount.toFixed(2)}</span>
              <span>Target: RM{campaign.targetAmount.toFixed(2)}</span>
            </div>
            <ProgressBar>
              <Progress
                $percentage={
                  (campaign.currentAmount / campaign.targetAmount) * 100
                }
              />
            </ProgressBar>
          </div>

          {/* Claims Progress Bar for Owners */}
          {isOwner && campaign.currentAmount > 0 && (
            <div style={{ marginTop: "15px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "5px",
                }}
              >
                <span>
                  Claimed: RM{(campaign.totalClaimed || 0).toFixed(2)}
                </span>
                <span>
                  {getClaimedPercentage(campaign).toFixed(0)}% of raised funds
                  (80% required to close)
                </span>
              </div>
              <ProgressBar>
                <Progress
                  $percentage={getClaimedPercentage(campaign)}
                  style={{
                    backgroundColor:
                      getClaimedPercentage(campaign) >= 80
                        ? "#4caf50"
                        : "#ff9800",
                  }}
                />
              </ProgressBar>
            </div>
          )}

          {/* Add the DonatorsList component here */}
          {campaign.donations && (
            <DonatorsList donations={campaign.donations} />
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
              marginTop: "10px",
            }}
          >
            {isOwner ? (
              <>
                {/* Show claim button if there are funds, regardless of status */}
                {campaign.currentAmount > 0 && (
                  <Button
                    onClick={() => {
                      setSelectedCampaign(campaign);
                      setShowClaimModal(true);
                    }}
                    style={{ backgroundColor: "#ff9800" }}
                  >
                    <FontAwesomeIcon icon={faUpload} /> Submit Claim
                  </Button>
                )}

                {/* Keep close button restricted to active campaigns */}
                {campaign.status === "active" && canCloseCampaign(campaign) && (
                  <Button
                    onClick={() => {
                      setSelectedCampaign(campaign);
                      setShowCloseConfirmModal(true);
                    }}
                    style={{ backgroundColor: "#4caf50" }}
                  >
                    <FontAwesomeIcon icon={faCheckCircle} /> Close Campaign
                  </Button>
                )}
              </>
            ) : (
              <div>
                {campaign.status === "active" &&
                  (() => {
                    const createdDate = new Date(campaign.createdAt);
                    const endDate = new Date(
                      createdDate.getTime() +
                        campaign.duration * 24 * 60 * 60 * 1000
                    );
                    const now = new Date();
                    const timeLeft = endDate - now;

                    if (timeLeft <= 0) {
                      return (
                        <span
                          style={{
                            color: "#666",
                            backgroundColor: "#f5f5f5",
                            padding: "8px 16px",
                            borderRadius: "4px",
                            display: "inline-block",
                          }}
                        >
                          Campaign has ended
                        </span>
                      );
                    }

                    return (
                      <Button onClick={() => handleDonateClick(campaign)}>
                        <FontAwesomeIcon icon={faHeart} /> Support
                      </Button>
                    );
                  })()}
              </div>
            )}
          </div>

          {/* Show Claims History for owners */}
          {isOwner && campaign.claims && campaign.claims.length > 0 && (
            <div style={{ marginTop: "20px" }}>
              <h4 style={{ color: "#377e62" }}>Claims History:</h4>
              <div style={{ maxHeight: "200px", overflowY: "auto" }}>
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <thead>
                    <tr style={{ backgroundColor: "#f5f5f5" }}>
                      <th
                        style={{
                          padding: "8px",
                          textAlign: "left",
                          border: "1px solid #ddd",
                        }}
                      >
                        Date
                      </th>
                      <th
                        style={{
                          padding: "8px",
                          textAlign: "left",
                          border: "1px solid #ddd",
                        }}
                      >
                        Amount
                      </th>
                      <th
                        style={{
                          padding: "8px",
                          textAlign: "left",
                          border: "1px solid #ddd",
                        }}
                      >
                        Comment
                      </th>
                      <th
                        style={{
                          padding: "8px",
                          textAlign: "left",
                          border: "1px solid #ddd",
                        }}
                      >
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {campaign.claims.map((claim, index) => (
                      <tr key={index}>
                        <td
                          style={{ padding: "8px", border: "1px solid #ddd" }}
                        >
                          {new Date(claim.date).toLocaleDateString()}
                        </td>
                        <td
                          style={{ padding: "8px", border: "1px solid #ddd" }}
                        >
                          RM{claim.amount.toFixed(2)}
                        </td>
                        <td
                          style={{ padding: "8px", border: "1px solid #ddd" }}
                        >
                          {claim.comment}
                        </td>
                        <td
                          style={{ padding: "8px", border: "1px solid #ddd" }}
                        >
                          <span
                            style={{
                              padding: "2px 6px",
                              borderRadius: "4px",
                              fontSize: "12px",
                              backgroundColor:
                                claim.status === "completed"
                                  ? "#e8f5e9"
                                  : claim.status === "rejected"
                                  ? "#ffebee"
                                  : "#fff3e0",
                              color:
                                claim.status === "completed"
                                  ? "#4caf50"
                                  : claim.status === "rejected"
                                  ? "#f44336"
                                  : "#ff9800",
                            }}
                          >
                            {claim.status}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      ))}

      {campaigns.length === 0 && (
        <p style={{ textAlign: "center", color: "#666" }}>No campaigns yet.</p>
      )}

      {/* Claim Modal */}
      {showClaimModal && (
        <Modal onClick={() => setShowClaimModal(false)}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <h3>Submit Claim for Campaign</h3>

            {claimError && <ErrorMessage>{claimError}</ErrorMessage>}
            {claimSuccess && <SuccessMessage>{claimSuccess}</SuccessMessage>}

            <div style={{ marginBottom: "15px" }}>
              <label
                style={{
                  display: "block",
                  marginBottom: "5px",
                  fontWeight: "bold",
                }}
              >
                Claim Amount (RM):
              </label>
              <Input
                type="number"
                value={claimAmount}
                onChange={(e) => setClaimAmount(e.target.value)}
                placeholder="Enter amount"
                min="0.01"
                step="0.01"
              />
            </div>

            <div style={{ marginBottom: "15px" }}>
              <label
                style={{
                  display: "block",
                  marginBottom: "5px",
                  fontWeight: "bold",
                }}
              >
                Comment (max 150 chars):
              </label>
              <TextArea
                value={claimComment}
                onChange={(e) => setClaimComment(e.target.value)}
                placeholder="Brief description of this claim"
                maxLength="150"
                style={{ minHeight: "80px" }}
              />
              <div
                style={{ textAlign: "right", fontSize: "0.8em", color: "#666" }}
              >
                {claimComment.length}/150 characters
              </div>
            </div>

            <div style={{ marginBottom: "20px" }}>
              <label
                style={{
                  display: "block",
                  marginBottom: "5px",
                  fontWeight: "bold",
                }}
              >
                Attachment (Receipt/Proof):
              </label>
              <input
                type="file"
                onChange={(e) => setClaimFile(e.target.files[0])}
                style={{
                  width: "100%",
                  padding: "8px",
                  border: "1px solid #ddd",
                  borderRadius: "4px",
                }}
              />
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                onClick={() => setShowClaimModal(false)}
                style={{ backgroundColor: "#666" }}
              >
                Cancel
              </Button>
              <Button onClick={handleClaimSubmit} disabled={isSubmitting}>
                {isSubmitting ? (
                  <>
                    <FontAwesomeIcon icon={faSpinner} spin /> Submitting...
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faUpload} /> Submit Claim
                  </>
                )}
              </Button>
            </div>
          </ModalContent>
        </Modal>
      )}

      {/* Close Campaign Confirmation Modal */}
      {showCloseConfirmModal && (
        <Modal onClick={() => setShowCloseConfirmModal(false)}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <h3>Close Campaign Confirmation</h3>

            {claimError && <ErrorMessage>{claimError}</ErrorMessage>}
            {claimSuccess && <SuccessMessage>{claimSuccess}</SuccessMessage>}

            <div style={{ margin: "20px 0" }}>
              <p>
                <strong>Are you sure you want to close this campaign?</strong>
              </p>
              <p>Once closed, you won't be able to:</p>
              <ul style={{ marginLeft: "20px" }}>
                <li>Receive more donations</li>
                <li>Submit more claims</li>
                <li>Reopen the campaign</li>
              </ul>

              <p style={{ marginTop: "15px" }}>
                Please ensure you have completed your project and provided all
                promised benefits to your funders.
              </p>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                onClick={() => setShowCloseConfirmModal(false)}
                style={{ backgroundColor: "#666" }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleCloseCampaign}
                disabled={isSubmitting}
                style={{ backgroundColor: "#4caf50" }}
              >
                {isSubmitting ? (
                  <>
                    <FontAwesomeIcon icon={faSpinner} spin /> Processing...
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faCheckCircle} /> Yes, Close Campaign
                  </>
                )}
              </Button>
            </div>
          </ModalContent>
        </Modal>
      )}
    </Section>
  );
};

const FundraiseTab = ({ userId }) => {
  const [isOwner, setIsOwner] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [walletBalance, setWalletBalance] = useState(0);
  const [showDonateModal, setShowDonateModal] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [donationAmount, setDonationAmount] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      const decoded = JSON.parse(atob(token.split(".")[1]));
      setIsOwner(decoded.userId === userId);
    }
    if (userId) {
      fetchData();
    }
  }, [userId]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      setError("");

      // Get campaigns (no token required)
      const campaignsRes = await axios.get(`/api/campaigns/${userId}`);
      setCampaigns(campaignsRes.data);

      // Get wallet balance only if user is logged in
      const token = localStorage.getItem("authToken");
      if (token) {
        const walletRes = await axios.get("/api/wallet", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setWalletBalance(walletRes.data.balance);
      }
    } catch (err) {
      console.error("Error fetching data:", err);
      setError(
        err.response?.data?.message || "Failed to load data. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleDonate = async () => {
    try {
      const amountRM = parseFloat(donationAmount);
      if (isNaN(amountRM) || amountRM <= 0) {
        setError("Please enter a valid amount");
        return;
      }

      // Add minimum donation check (RM5 = 500 Boons)
      if (amountRM < 5) {
        setError("Minimum donation amount is RM5 (500 Boons)");
        return;
      }

      // Convert donation amount to Boons for comparison
      const donationBoons = Math.round(amountRM * 100);
      const walletBalanceBoons = Math.round(walletBalance * 100);

      if (walletBalanceBoons < donationBoons) {
        setError(
          `Insufficient funds. You need ${donationBoons.toLocaleString()} Boons (RM${amountRM.toFixed(
            2
          )}). Your balance: ${walletBalanceBoons.toLocaleString()} Boons (RM${walletBalance.toFixed(
            2
          )})`
        );
        return;
      }

      const token = localStorage.getItem("authToken");
      if (!token) {
        setError("Please log in to donate");
        return;
      }

      await axios.post(
        `/api/campaigns/${selectedCampaign._id}/donate`,
        { amount: amountRM },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      await fetchData();
      setShowDonateModal(false);
      setDonationAmount("");
      setSuccess("Donation successful!");
    } catch (err) {
      console.error("Donation error:", err);
      setError(err.response?.data?.message || "Failed to process donation");
    }
  };

  if (isLoading) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <FontAwesomeIcon icon={faSpinner} spin size="2x" />
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div>
      {campaigns.length > 0 && (
        <TotalProgress>
          <h2>Total Fundraising Progress</h2>
          <ProgressBar>
            <Progress
              $percentage={
                (campaigns.reduce((acc, c) => acc + c.currentAmount, 0) /
                  campaigns.reduce((acc, c) => acc + c.targetAmount, 0)) *
                100
              }
            />
          </ProgressBar>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>
              Raised: RM
              {campaigns
                .reduce((acc, c) => acc + c.currentAmount, 0)
                .toFixed(2)}
            </span>
            <span>
              Target: RM
              {campaigns.reduce((acc, c) => acc + c.targetAmount, 0).toFixed(2)}
            </span>
          </div>
        </TotalProgress>
      )}

      {error && <ErrorMessage>{error}</ErrorMessage>}
      {success && <SuccessMessage>{success}</SuccessMessage>}

      {isOwner && (
        <CampaignForm
          onCreate={fetchData}
          walletBalance={walletBalance}
          setError={setError}
        />
      )}

      <CampaignList
        campaigns={campaigns}
        isOwner={isOwner}
        onDonate={(campaign) => {
          setSelectedCampaign(campaign);
          setShowDonateModal(true);
        }}
      />

      {showDonateModal && (
        <Modal onClick={() => setShowDonateModal(false)}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <h3>Support this Campaign</h3>
            <Input
              type="number"
              placeholder="Enter amount (RM) - Minimum RM5"
              value={donationAmount}
              onChange={(e) => setDonationAmount(e.target.value)}
              min="5" // Add minimum attribute
              step="0.1" // Allow decimal values
            />
            <div style={{ color: "#666", margin: "10px 0" }}>
              Your wallet balance:{" "}
              {Math.round(walletBalance * 100).toLocaleString()} Boons (RM
              {walletBalance.toFixed(2)})
            </div>
            <Button onClick={handleDonate}>
              <FontAwesomeIcon icon={faHeart} /> Donate
            </Button>
            <Button
              onClick={() => setShowDonateModal(false)}
              style={{ backgroundColor: "#666" }}
            >
              Cancel
            </Button>
          </ModalContent>
        </Modal>
      )}
    </div>
  );
};

export default FundraiseTab;
