import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignOutAlt,
  faTrash,
  faChevronUp,
  faChevronDown,
  faEnvelope,
  faSpinner,
  faPaperclip,
} from "@fortawesome/free-solid-svg-icons";
import { Editor } from "@tinymce/tinymce-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Search } from "lucide-react";

// Find where other styled components are defined (typically near the top)
// and add these:

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  min-height: 100px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  resize: vertical;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 10px;
`;

const PaginationControls = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const PageButton = styled.button`
  padding: 5px 10px;
  border: 1px solid #ddd;
  background: ${(props) => (props.active ? "#377e62" : "white")};
  color: ${(props) => (props.active ? "white" : "black")};
  border-radius: 4px;
  cursor: pointer;
  &:disabled {
    background-color: #f0f0f0;
    cursor: not-allowed;
  }
  &:hover:not(:disabled) {
    background-color: ${(props) => (props.active ? "#2c6a52" : "#f0f0f0")};
  }
`;

const PageSize = styled.select`
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ddd;
`;

const SellerSection = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 15px;
  overflow: hidden;
`;

const SellerHeader = styled.div`
  background-color: #377e62;
  color: white;
  padding: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background-color: #2c6a52;
  }
`;

const ProductSection = styled.div`
  border-bottom: 1px solid #e0e0e0;
  margin: 10px;
  padding: 10px;
`;

const ProductHeader = styled.div`
  background-color: #f5f5f5;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  &:hover {
    background-color: #e0e0e0;
  }
`;

const ExpandIcon = styled.span`
  margin-left: 10px;
`;

const ShowPhotoButton = styled.button`
  background-color: #377e62;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  &:hover {
    background-color: #2c6a52;
  }
`;

const DeliveryProofCard = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const DeliveryProofImage = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 4px;
  margin-top: 10px;
`;

const DeliveryProofGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
`;

const OrderDetails = styled.div`
  margin-top: 10px;
  font-size: 14px;
  color: #666;

  p {
    margin: 5px 0;
  }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 20px;
  color: #377e62;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const Tab = styled.button`
  padding: 10px 20px;
  border: none;
  background-color: ${(props) => (props.active ? "#377E62" : "#f0f0f0")};
  color: ${(props) => (props.active ? "white" : "black")};
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: ${(props) => (props.active ? "#2c6a52" : "#e0e0e0")};
  }
`;

const ProductCard = styled.div`
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 15px;
  display: flex;
  gap: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ProductImage = styled.img`
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 4px;
`;

const ProductInfo = styled.div`
  flex: 1;
`;

const ProductName = styled.h3`
  margin: 0 0 10px 0;
  color: #377e62;
`;

const ProductPrice = styled.p`
  margin: 0 0 10px 0;
  font-weight: bold;
`;

const LocationLink = styled.p`
  color: #377e62;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const ProductContact = styled.a`
  color: #377e62;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;

const Button = styled.button`
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  background-color: #377e62;
  color: white;
  &:hover {
    background-color: #2c6a52;
  }
`;

const ApproveButton = styled(Button)`
  background-color: #4caf50;
  &:hover {
    background-color: #45a049;
  }
`;

const RejectButton = styled(Button)`
  background-color: #f44336;
  &:hover {
    background-color: #da190b;
  }
`;

const LogoutButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: background-color 0.3s;
  &:hover {
    background-color: #d32f2f;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 30px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  &:focus {
    outline: none;
    border-color: #377e62;
  }
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

const Th = styled.th`
  text-align: left;
  padding: 12px;
  background-color: #f2f2f2;
  border-bottom: 2px solid #ddd;
`;

const Td = styled.td`
  padding: 12px;
  border-bottom: 1px solid #ddd;
`;

const ErrorMessage = styled.p`
  color: #f44336;
  margin-top: 10px;
`;

const SuccessMessage = styled.p`
  color: #4caf50;
  margin-top: 10px;
`;

const InfoText = styled.p`
  color: #666;
  margin-bottom: 15px;
  font-size: 0.9em;
`;

const PriceReferenceContainer = styled.div`
  margin-top: 10px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 4px;
  border-left: 4px solid
    ${(props) => {
      switch (props.status) {
        case "below":
          return "#2196F3"; // Blue
        case "within":
          return "#4CAF50"; // Green
        case "above":
          return "#f44336"; // Red
        default:
          return "#757575"; // Grey
      }
    }};
`;

const PriceReferenceTitle = styled.h4`
  margin: 0 0 8px 0;
  color: #333;
  font-size: 14px;
`;

const PriceReferenceInfo = styled.p`
  margin: 4px 0;
  font-size: 13px;
  color: #666;
`;

const PriceStatus = styled.span`
  display: inline-block;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  color: white;
  background-color: ${(props) => {
    switch (props.status) {
      case "below":
        return "#2196F3"; // Blue
      case "within":
        return "#4CAF50"; // Green
      case "above":
        return "#f44336"; // Red
      default:
        return "#757575"; // Grey
    }
  }};
`;

const UserTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

const EmailModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 1000px;
  max-height: 90vh;
  overflow-y: auto;
`;

const AttachmentPreview = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  background: #f5f5f5;
  border-radius: 4px;
  margin: 4px 0;
  justify-content: space-between;
`;

function AdminDashboard() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("products");
  const [pendingProducts, setPendingProducts] = useState([]);
  const [topUpCodes, setTopUpCodes] = useState([]);
  const [newTopUpCode, setNewTopUpCode] = useState({
    code: "",
    validFrom: "",
    validTo: "",
    entitledUsers: "",
    usageLimit: "",
    amount: "",
    isPublic: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [postPrice, setPostPrice] = useState("");
  const [deliveryProofs, setDeliveryProofs] = useState([]);
  const [expandedSellers, setExpandedSellers] = useState(new Set());
  const [expandedProducts, setExpandedProducts] = useState(new Set());
  const [shownPhotos, setShownPhotos] = useState(new Set());
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [emailModal, setEmailModal] = useState(false);
  const [emailContent, setEmailContent] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [sending, setSending] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [emailSignature, setEmailSignature] = useState("");
  const [scheduledDateTime, setScheduledDateTime] = useState("");
  const [scheduledEmails, setScheduledEmails] = useState([]);
  const [editingEmail, setEditingEmail] = useState(null);
  const [recipientModal, setRecipientModal] = useState(false);
  const [campaignClaims, setCampaignClaims] = useState([]);
  const [expandedCampaigns, setExpandedCampaigns] = useState(new Set());
  const [expandedClaims, setExpandedClaims] = useState(new Set());
  const [claimStatusModal, setClaimStatusModal] = useState(false);
  const [selectedClaim, setSelectedClaim] = useState(null);
  const [claimStatusComment, setClaimStatusComment] = useState("");
  const [newClaimStatus, setNewClaimStatus] = useState("");

  useEffect(() => {
    const filtered = users.filter(
      (user) =>
        user.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.mobileNumber?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredUsers(filtered);
    setTotalPages(Math.ceil(filtered.length / pageSize));
  }, [searchTerm, users, pageSize]);

  useEffect(() => {
    const isAdmin = localStorage.getItem("isAdmin") === "true";
    if (!isAdmin) {
      navigate("/login");
    } else {
      fetchPendingProducts();
      fetchTopUpCodes();
      fetchPostPrice();
      fetchDeliveryProofs();
    }
  }, [navigate]);

  useEffect(() => {
    setTotalPages(Math.ceil(users.length / pageSize));
  }, [users.length, pageSize]);

  useEffect(() => {
    if (activeTab === "users") {
      const fetchUsers = async () => {
        try {
          const response = await axios.get("/api/users", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          });
          setUsers(response.data);
          setTotalPages(Math.ceil(response.data.length / pageSize));
        } catch (error) {
          console.error("Error fetching users:", error);
          setError("Failed to fetch users");
        }
      };
      fetchUsers();
    }
  }, [activeTab, pageSize, navigate]);

  useEffect(() => {
    if (activeTab === "campaignClaims") {
      const fetchCampaignClaims = async () => {
        try {
          setIsLoading(true);
          const response = await axios.get("/api/admin/campaign-claims", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          });
          setCampaignClaims(response.data);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching campaign claims:", error);
          setError("Failed to fetch campaign claims");
          setIsLoading(false);
        }
      };
      fetchCampaignClaims();
    }
  }, [activeTab]);

  useEffect(() => {
    if (activeTab === "users") {
      // Fetch email signature
      axios
        .get("/api/admin/email-signature", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        })
        .then((response) => setEmailSignature(response.data.signature))
        .catch((error) => console.error("Error fetching signature:", error));

      // Fetch scheduled emails
      axios
        .get("/api/admin/scheduled-emails", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        })
        .then((response) => setScheduledEmails(response.data))
        .catch((error) =>
          console.error("Error fetching scheduled emails:", error)
        );
    }
  }, [activeTab]);

  const toggleCampaign = (campaignId) => {
    setExpandedCampaigns((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(campaignId)) {
        newSet.delete(campaignId);
      } else {
        newSet.add(campaignId);
      }
      return newSet;
    });
  };

  const toggleClaim = (claimId) => {
    setExpandedClaims((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(claimId)) {
        newSet.delete(claimId);
      } else {
        newSet.add(claimId);
      }
      return newSet;
    });
  };

  const openStatusModal = (campaignId, claim) => {
    console.log("Opening modal with:", { campaignId, claim }); // Debug log

    // Make sure we have the necessary IDs
    if (!campaignId || !claim._id) {
      console.error("Missing required IDs:", {
        campaignId,
        claimId: claim._id,
      });
      setError("Invalid claim data");
      return;
    }

    setSelectedClaim({
      campaignId: campaignId,
      claimId: claim._id,
      currentStatus: claim.status || "pending",
      amount: claim.amount,
      comment: claim.comment,
      date: claim.date,
      campaignTitle: claim.campaignTitle,
      fundraiserName: claim.fundraiserName,
      fundraiserEmail: claim.fundraiserEmail,
      fileUrl: claim.fileUrl || null,
      adminComment: claim.adminComment || "",
    });

    // Set the initial status for the dropdown
    setNewClaimStatus(claim.status || "pending");

    // Set any existing admin comment
    setClaimStatusComment(claim.adminComment || "");

    // Open the modal
    setClaimStatusModal(true);
  };

  const handleUpdateClaimStatus = async () => {
    try {
      setIsLoading(true);
      console.log("Selected Claim:", selectedClaim);
      console.log("New Status:", newClaimStatus);
      console.log("Comment:", claimStatusComment);

      // Validation: only rejected status requires a comment
      if (
        newClaimStatus === "rejected" &&
        (!claimStatusComment || claimStatusComment.trim() === "")
      ) {
        setError("A comment is required when rejecting a claim");
        setIsLoading(false);
        return;
      }

      if (!selectedClaim?.campaignId || !selectedClaim?.claimId) {
        console.error("Missing IDs:", {
          campaignId: selectedClaim?.campaignId,
          claimId: selectedClaim?.claimId,
        });
        setError("Invalid claim data");
        setIsLoading(false);
        return;
      }

      const url = `/api/admin/campaign-claims/${selectedClaim.campaignId}/${selectedClaim.claimId}/status`;

      // For non-rejected statuses, don't send the comment even if it exists
      const requestBody = {
        status: newClaimStatus,
        ...(newClaimStatus === "rejected"
          ? { comment: claimStatusComment }
          : {}),
      };

      console.log("Request body:", requestBody);

      await axios.post(url, requestBody, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });

      setSuccessMessage("Claim status updated successfully");
      setClaimStatusModal(false);

      // Refresh the campaign claims data
      const refreshResponse = await axios.get("/api/admin/campaign-claims", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      setCampaignClaims(refreshResponse.data);
    } catch (error) {
      console.error("Error updating claim status:", error.response || error);
      setError(
        error.response?.data?.message || "Failed to update claim status"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const toggleSeller = (email) => {
    setExpandedSellers((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(email)) {
        newSet.delete(email);
      } else {
        newSet.add(email);
      }
      return newSet;
    });
  };

  const toggleProduct = (productId) => {
    setExpandedProducts((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(productId)) {
        newSet.delete(productId);
      } else {
        newSet.add(productId);
      }
      return newSet;
    });
  };

  const togglePhoto = (proofId) => {
    setShownPhotos((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(proofId)) {
        newSet.delete(proofId);
      } else {
        newSet.add(proofId);
      }
      return newSet;
    });
  };

  const groupByEmail = (proofs) => {
    const grouped = {};
    proofs
      .filter((proof) => proof.orderStatus === "done")
      .forEach((proof) => {
        const email = proof.seller.email;
        if (!grouped[email]) {
          grouped[email] = {
            seller: proof.seller,
            products: {},
          };
        }
        if (!grouped[email].products[proof.productId]) {
          grouped[email].products[proof.productId] = {
            productName: proof.productName,
            proofs: [],
          };
        }
        grouped[email].products[proof.productId].proofs.push(proof);
      });
    return grouped;
  };

  const fetchDeliveryProofs = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("/api/admin/delivery-proofs", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      setDeliveryProofs(response.data);
    } catch (error) {
      console.error("Error fetching delivery proofs:", error);
      setError("Failed to fetch delivery proofs. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPendingProducts = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("/api/admin/pending-products", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      setPendingProducts(response.data.products);
    } catch (error) {
      console.error("Error fetching pending products:", error);
      setError("Failed to fetch pending products. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTopUpCodes = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("/api/admin/topup-codes", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      setTopUpCodes(response.data);
    } catch (error) {
      console.error("Error fetching top-up codes:", error);
      setError("Failed to fetch top-up codes. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPostPrice = async () => {
    try {
      const response = await axios.get("/api/post-price");
      // Convert RM to Boons
      setPostPrice((response.data.price * 100).toString());
    } catch (error) {
      console.error("Error fetching post price:", error);
      setError("Failed to fetch post price. Please try again.");
    }
  };

  const handleApprove = async (productId) => {
    try {
      await axios.post(
        `/api/admin/approve-product/${productId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      fetchPendingProducts();
      setSuccessMessage("Product approved successfully");
    } catch (error) {
      console.error("Error approving product:", error);
      setError("Failed to approve product. Please try again.");
    }
  };

  const handleReject = async (productId) => {
    try {
      await axios.post(
        `/api/admin/reject-product/${productId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      fetchPendingProducts();
      setSuccessMessage("Product rejected successfully");
    } catch (error) {
      console.error("Error rejecting product:", error);
      setError("Failed to reject product. Please try again.");
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("isAdmin");
    navigate("/login");
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === "amount") {
      // Ensure amount is always a whole number for Boons
      const numValue = parseInt(value) || "";
      setNewTopUpCode((prevState) => ({
        ...prevState,
        [name]: numValue,
      }));
    } else {
      setNewTopUpCode((prevState) => ({
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const handleSubmitTopUpCode = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setSuccessMessage("");

    try {
      const codeData = {
        ...newTopUpCode,
        // Convert Boons to RM for API
        amount: parseFloat(newTopUpCode.amount) / 100,
        entitledUsers: newTopUpCode.isPublic
          ? []
          : newTopUpCode.entitledUsers.split(",").map((email) => email.trim()),
      };

      await axios.post("/api/admin/topup-code", codeData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });

      setSuccessMessage("Top-up code created successfully!");
      setNewTopUpCode({
        code: "",
        validFrom: "",
        validTo: "",
        entitledUsers: "",
        usageLimit: "",
        amount: "",
        isPublic: false,
      });
      fetchTopUpCodes();
    } catch (error) {
      console.error("Error creating top-up code:", error);
      setError(
        error.response?.data?.message ||
          "Failed to create top-up code. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteTopUpCode = async (codeId) => {
    if (window.confirm("Are you sure you want to delete this top-up code?")) {
      try {
        await axios.delete(`/api/admin/topup-code/${codeId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        });
        setSuccessMessage("Top-up code deleted successfully");
        fetchTopUpCodes();
      } catch (error) {
        console.error("Error deleting top-up code:", error);
        setError("Failed to delete top-up code. Please try again.");
      }
    }
  };

  const handlePostPriceChange = (e) => {
    // Ensure post price is always a whole number for Boons
    const value = parseInt(e.target.value) || "";
    setPostPrice(value.toString());
  };

  const handleUpdatePostPrice = async () => {
    try {
      // Convert Boons back to RM for API
      await axios.post(
        "/api/update-post-price",
        { price: parseFloat(postPrice) / 100 },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      setSuccessMessage("Post price updated successfully");
    } catch (error) {
      console.error("Error updating post price:", error);
      setError("Failed to update post price. Please try again.");
    }
  };

  const handleLocationClick = (lat, lon) => {
    window.open(`https://www.google.com/maps?q=${lat},${lon}`);
  };

  return (
    <Container>
      <Title>Admin Dashboard</Title>
      <LogoutButton onClick={handleLogout}>
        <FontAwesomeIcon icon={faSignOutAlt} /> Logout
      </LogoutButton>

      <TabContainer>
        <Tab
          active={activeTab === "products"}
          onClick={() => setActiveTab("products")}
        >
          Pending Products
        </Tab>
        <Tab
          active={activeTab === "topUpCodes"}
          onClick={() => setActiveTab("topUpCodes")}
        >
          Top-up Codes
        </Tab>
        <Tab
          active={activeTab === "postPrice"}
          onClick={() => setActiveTab("postPrice")}
        >
          Post Price
        </Tab>
        <Tab
          active={activeTab === "deliveryProofs"}
          onClick={() => setActiveTab("deliveryProofs")}
        >
          Delivery Proofs
        </Tab>
        <Tab
          active={activeTab === "campaignClaims"}
          onClick={() => setActiveTab("campaignClaims")}
        >
          Campaign Claims
        </Tab>
        <Tab
          active={activeTab === "users"}
          onClick={() => setActiveTab("users")}
        >
          Users
        </Tab>
        <Tab
          active={activeTab === "scheduledEmails"}
          onClick={() => setActiveTab("scheduledEmails")}
        >
          Scheduled Emails
        </Tab>
      </TabContainer>

      {error && <ErrorMessage>{error}</ErrorMessage>}
      {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}

      {activeTab === "products" && (
        <>
          <h2>Pending Products</h2>
          {isLoading ? (
            <p>Loading pending products...</p>
          ) : pendingProducts.length > 0 ? (
            pendingProducts.map((product) => (
              <ProductCard key={product._id}>
                <ProductImage src={product.image} alt={product.name} />
                <ProductInfo>
                  <ProductName>{product.name}</ProductName>
                  <ProductPrice>
                    RM {product.price.toFixed(2)}/{product.unit}
                  </ProductPrice>
                  <LocationLink
                    onClick={() =>
                      handleLocationClick(
                        product.location.lat,
                        product.location.lon
                      )
                    }
                  >
                    {product.locationDetail}
                  </LocationLink>

                  {product.priceReference && (
                    <PriceReferenceContainer
                      status={product.priceReference.status}
                    >
                      <PriceReferenceTitle>
                        Price Reference: {product.priceReference.name}
                      </PriceReferenceTitle>
                      <PriceReferenceInfo>
                        Market Price: RM{" "}
                        {product.priceReference.meanPrice.toFixed(2)}/
                        {product.priceReference.unit}
                      </PriceReferenceInfo>
                      <PriceReferenceInfo>
                        Range: RM {product.priceReference.min.toFixed(2)} - RM{" "}
                        {product.priceReference.max.toFixed(2)}
                      </PriceReferenceInfo>
                      <PriceReferenceInfo>
                        Converted Price: RM{" "}
                        {product.priceReference.convertedPrice.toFixed(2)}/
                        {product.priceReference.unit}
                      </PriceReferenceInfo>
                      <PriceReferenceInfo>
                        Status:{" "}
                        <PriceStatus status={product.priceReference.status}>
                          {product.priceReference.status.toUpperCase()}
                        </PriceStatus>
                      </PriceReferenceInfo>
                    </PriceReferenceContainer>
                  )}

                  <ButtonGroup>
                    <ApproveButton onClick={() => handleApprove(product._id)}>
                      Approve
                    </ApproveButton>
                    <RejectButton onClick={() => handleReject(product._id)}>
                      Reject
                    </RejectButton>
                  </ButtonGroup>
                </ProductInfo>
              </ProductCard>
            ))
          ) : (
            <p>No pending products found.</p>
          )}
        </>
      )}

      {activeTab === "campaignClaims" && (
        <>
          <h2>Campaign Claims</h2>
          {isLoading ? (
            <div style={{ textAlign: "center", padding: "20px" }}>
              <FontAwesomeIcon icon={faSpinner} spin size="2x" />
              <p>Loading campaign claims...</p>
            </div>
          ) : campaignClaims.length > 0 ? (
            campaignClaims.map((campaign) => (
              <SellerSection key={campaign.campaignId}>
                <SellerHeader
                  onClick={() => toggleCampaign(campaign.campaignId)}
                >
                  <div>
                    <strong>{campaign.campaignTitle}</strong> -{" "}
                    {campaign.fundraiser.name} ({campaign.fundraiser.email})
                  </div>
                  <ExpandIcon>
                    <FontAwesomeIcon
                      icon={
                        expandedCampaigns.has(campaign.campaignId)
                          ? faChevronUp
                          : faChevronDown
                      }
                    />
                  </ExpandIcon>
                </SellerHeader>

                {expandedCampaigns.has(campaign.campaignId) && (
                  <div style={{ padding: "15px" }}>
                    <h3>Claims</h3>

                    {campaign.claims.map((claim) => (
                      <div
                        key={claim._id}
                        style={{
                          border: "1px solid #ddd",
                          borderRadius: "4px",
                          padding: "15px",
                          marginBottom: "15px",
                          backgroundColor:
                            claim.status === "completed"
                              ? "#e8f5e9"
                              : claim.status === "rejected"
                              ? "#ffebee"
                              : claim.status === "in_progress"
                              ? "#e3f2fd"
                              : "#fff3e0",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <h4 style={{ margin: "0 0 10px 0" }}>
                              Amount: RM{claim.amount.toFixed(2)} -
                              <span
                                style={{
                                  marginLeft: "10px",
                                  padding: "2px 6px",
                                  borderRadius: "4px",
                                  fontSize: "12px",
                                  color: "white",
                                  backgroundColor:
                                    claim.status === "completed"
                                      ? "#4caf50"
                                      : claim.status === "rejected"
                                      ? "#f44336"
                                      : claim.status === "in_progress"
                                      ? "#2196f3"
                                      : "#ff9800",
                                }}
                              >
                                {claim.status.toUpperCase().replace("_", " ")}
                              </span>
                            </h4>
                            <p style={{ margin: "0 0 5px 0" }}>
                              Date: {new Date(claim.date).toLocaleString()}
                            </p>
                            <p style={{ margin: "0 0 5px 0" }}>
                              Comment: "{claim.comment}"
                            </p>

                            {claim.adminComment && (
                              <div
                                style={{
                                  padding: "10px",
                                  backgroundColor: "#f5f5f5",
                                  borderRadius: "4px",
                                  marginTop: "10px",
                                }}
                              >
                                <p style={{ margin: "0", fontWeight: "bold" }}>
                                  Admin Comment:
                                </p>
                                <p style={{ margin: "5px 0 0 0" }}>
                                  {claim.adminComment}
                                </p>
                              </div>
                            )}
                          </div>

                          <div>
                            <Button
                              onClick={() =>
                                openStatusModal(campaign.campaignId, claim)
                              }
                              style={{ marginLeft: "10px" }}
                            >
                              Update Status
                            </Button>

                            {claim.fileUrl && (
                              <Button
                                onClick={() =>
                                  window.open(claim.fileUrl, "_blank")
                                }
                                style={{
                                  marginLeft: "10px",
                                  marginTop: "10px",
                                }}
                              >
                                <FontAwesomeIcon icon={faPaperclip} /> View
                                Attachment
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </SellerSection>
            ))
          ) : (
            <p>No campaign claims found.</p>
          )}

          {/* Status Update Modal */}
          {claimStatusModal && selectedClaim && (
            <Modal onClick={() => setClaimStatusModal(false)}>
              <ModalContent onClick={(e) => e.stopPropagation()}>
                <h3>Update Claim Status</h3>

                <div style={{ marginBottom: "20px" }}>
                  <h4>Claim Details</h4>
                  <p>
                    <strong>Campaign:</strong> {selectedClaim.campaignTitle}
                  </p>
                  <p>
                    <strong>Fundraiser:</strong> {selectedClaim.fundraiserName}{" "}
                    ({selectedClaim.fundraiserEmail})
                  </p>
                  <p>
                    <strong>Amount:</strong> RM{selectedClaim.amount.toFixed(2)}
                  </p>
                  <p>
                    <strong>Date:</strong>{" "}
                    {new Date(selectedClaim.date).toLocaleString()}
                  </p>
                  <p>
                    <strong>Comment:</strong> "{selectedClaim.comment}"
                  </p>
                  <p>
                    <strong>Current Status:</strong>{" "}
                    {selectedClaim.currentStatus
                      .toUpperCase()
                      .replace("_", " ")}
                  </p>
                </div>

                <div style={{ marginBottom: "20px" }}>
                  <label
                    style={{
                      display: "block",
                      marginBottom: "8px",
                      fontWeight: "bold",
                    }}
                  >
                    New Status:
                  </label>
                  <select
                    value={newClaimStatus}
                    onChange={(e) => setNewClaimStatus(e.target.value)}
                    style={{
                      width: "100%",
                      padding: "10px",
                      borderRadius: "4px",
                      border: "1px solid #ddd",
                      fontSize: "16px",
                    }}
                  >
                    <option value="pending">Pending</option>
                    <option value="in_progress">In Progress</option>
                    <option value="completed">Completed</option>
                    <option value="rejected">Rejected</option>
                  </select>
                </div>

                <div style={{ marginBottom: "20px" }}>
                  <label
                    style={{
                      display: "block",
                      marginBottom: "8px",
                      fontWeight: "bold",
                    }}
                  >
                    Admin Comment{" "}
                    {newClaimStatus === "rejected" && "(Required for denial)"}:
                  </label>
                  <TextArea
                    value={claimStatusComment}
                    onChange={(e) => setClaimStatusComment(e.target.value)}
                    placeholder={
                      newClaimStatus === "rejected"
                        ? "Please explain why this claim is being denied..."
                        : "Add any notes about this status change (optional)"
                    }
                    required={newClaimStatus === "rejected"}
                    style={{ minHeight: "120px" }}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <Button
                    onClick={() => setClaimStatusModal(false)}
                    style={{ backgroundColor: "#666" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleUpdateClaimStatus}
                    disabled={
                      isLoading ||
                      (newClaimStatus === "rejected" &&
                        !claimStatusComment.trim())
                    }
                  >
                    {isLoading ? (
                      <>
                        <FontAwesomeIcon icon={faSpinner} spin /> Updating...
                      </>
                    ) : (
                      "Update Status"
                    )}
                  </Button>
                </div>
              </ModalContent>
            </Modal>
          )}
        </>
      )}

      {activeTab === "topUpCodes" && (
        <>
          <h2>Create Top-up Code</h2>
          <InfoText>Enter amounts in Boons (1 RM = 100 Boons)</InfoText>
          <Form onSubmit={handleSubmitTopUpCode}>
            <Input
              name="code"
              value={newTopUpCode.code}
              onChange={handleInputChange}
              placeholder="Code"
              required
            />
            <Input
              name="validFrom"
              type="datetime-local"
              value={newTopUpCode.validFrom}
              onChange={handleInputChange}
              required
            />
            <Input
              name="validTo"
              type="datetime-local"
              value={newTopUpCode.validTo}
              onChange={handleInputChange}
              required
            />
            <Input
              name="usageLimit"
              type="number"
              value={newTopUpCode.usageLimit}
              onChange={handleInputChange}
              placeholder="Usage Limit"
              min="1"
              required
            />
            <Input
              name="amount"
              type="number"
              value={newTopUpCode.amount}
              onChange={handleInputChange}
              placeholder="Amount (in Boons)"
              min="1"
              required
            />
            <CheckboxLabel>
              <input
                type="checkbox"
                name="isPublic"
                checked={newTopUpCode.isPublic}
                onChange={handleInputChange}
              />
              Public Code (available to all users)
            </CheckboxLabel>
            {!newTopUpCode.isPublic && (
              <Input
                name="entitledUsers"
                value={newTopUpCode.entitledUsers}
                onChange={handleInputChange}
                placeholder="Entitled User Emails (comma-separated)"
              />
            )}
            <Button type="submit" disabled={isLoading}>
              {isLoading ? "Creating..." : "Create Top-up Code"}
            </Button>
          </Form>

          <h3>Existing Top-up Codes</h3>
          {isLoading ? (
            <p>Loading top-up codes...</p>
          ) : topUpCodes.length > 0 ? (
            <Table>
              <thead>
                <tr>
                  <Th>Code</Th>
                  <Th>Valid From</Th>
                  <Th>Valid To</Th>
                  <Th>Usage Limit</Th>
                  <Th>Amount</Th>
                  <Th>Public</Th>
                  <Th>Entitled Users</Th>
                  <Th>Actions</Th>
                </tr>
              </thead>
              <tbody>
                {topUpCodes.map((code) => (
                  <tr key={code._id}>
                    <Td>{code.code}</Td>
                    <Td>{new Date(code.validFrom).toLocaleString()}</Td>
                    <Td>{new Date(code.validTo).toLocaleString()}</Td>
                    <Td>{code.usageLimit}</Td>
                    <Td>{(code.amount * 100).toFixed(0)} Boons</Td>
                    <Td>{code.isPublic ? "Yes" : "No"}</Td>
                    <Td>
                      {code.isPublic
                        ? "All Users"
                        : code.entitledUsers
                            .map((user) => user.email)
                            .join(", ")}
                    </Td>
                    <Td>
                      <Button onClick={() => handleDeleteTopUpCode(code._id)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </Td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No top-up codes found.</p>
          )}
        </>
      )}

      {activeTab === "postPrice" && (
        <>
          <h2>Set Post Price</h2>
          <InfoText>Enter the post price in Boons (1 RM = 100 Boons)</InfoText>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleUpdatePostPrice();
            }}
          >
            <Input
              type="number"
              value={postPrice}
              onChange={handlePostPriceChange}
              placeholder="Post Price (in Boons)"
              min="1"
              required
            />
            <Button type="submit" disabled={isLoading}>
              {isLoading ? "Updating..." : "Update Post Price"}
            </Button>
          </Form>
        </>
      )}

      {activeTab === "users" && (
        <>
          <h2>Users</h2>
          <div className="flex items-center gap-4 mb-4">
            <div className="relative flex-1 max-w-md">
              <Search
                className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                size={20}
              />
              <Input
                type="text"
                placeholder="Search by email, name, or mobile..."
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  setCurrentPage(1);
                }}
                className="pl-10"
              />
            </div>
            <ButtonGroup>
              <Button
                onClick={() => setEmailModal(true)}
                disabled={selectedUsers.length === 0}
              >
                <FontAwesomeIcon icon={faEnvelope} /> Send Email to Selected
              </Button>
            </ButtonGroup>
          </div>

          <UserTable>
            <thead>
              <tr>
                <Th>
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedUsers(users.map((user) => user._id));
                      } else {
                        setSelectedUsers([]);
                      }
                    }}
                  />
                </Th>
                <Th>Index</Th>
                <Th>Email</Th>
                <Th>Name</Th>
                <Th>Mobile</Th>
                <Th>Registration Date</Th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers
                .slice((currentPage - 1) * pageSize, currentPage * pageSize)
                .map((user, index) => (
                  <tr key={user._id}>
                    <Td>
                      <input
                        type="checkbox"
                        checked={selectedUsers.includes(user._id)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedUsers([...selectedUsers, user._id]);
                          } else {
                            setSelectedUsers(
                              selectedUsers.filter((id) => id !== user._id)
                            );
                          }
                        }}
                      />
                    </Td>
                    <Td>{(currentPage - 1) * pageSize + index + 1}</Td>
                    <Td>{user.email}</Td>
                    <Td>{user.name || "-"}</Td>
                    <Td>{user.mobileNumber || "-"}</Td>
                    <Td>{new Date(user.createdAt).toLocaleDateString()}</Td>
                  </tr>
                ))}
            </tbody>
          </UserTable>

          <PaginationContainer>
            <div>
              Showing{" "}
              {Math.min((currentPage - 1) * pageSize + 1, filteredUsers.length)}{" "}
              to {Math.min(currentPage * pageSize, filteredUsers.length)} of{" "}
              {filteredUsers.length} entries to{" "}
              {Math.min(currentPage * pageSize, users.length)} of {users.length}{" "}
              entries
            </div>
            <PaginationControls>
              <PageSize
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                  setCurrentPage(1);
                }}
              >
                <option value={5}>5 per page</option>
                <option value={10}>10 per page</option>
                <option value={25}>25 per page</option>
                <option value={50}>50 per page</option>
              </PageSize>
              <PageButton
                onClick={() => setCurrentPage(1)}
                disabled={currentPage === 1}
              >
                First
              </PageButton>
              <PageButton
                onClick={() => setCurrentPage((prev) => prev - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </PageButton>
              {[...Array(totalPages)].map((_, i) => (
                <PageButton
                  key={i + 1}
                  active={currentPage === i + 1}
                  onClick={() => setCurrentPage(i + 1)}
                >
                  {i + 1}
                </PageButton>
              ))}
              <PageButton
                onClick={() => setCurrentPage((prev) => prev + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </PageButton>
              <PageButton
                onClick={() => setCurrentPage(totalPages)}
                disabled={currentPage === totalPages}
              >
                Last
              </PageButton>
            </PaginationControls>
          </PaginationContainer>

          {emailModal && (
            <EmailModal>
              <div style={{ position: "relative", padding: "20px" }}>
                <h3 style={{ marginBottom: "20px" }}>Send Bulk Email</h3>

                <div style={{ marginBottom: "20px" }}>
                  <label style={{ display: "block", marginBottom: "5px" }}>
                    Subject:
                  </label>
                  <Input
                    type="text"
                    placeholder="Enter email subject"
                    value={emailSubject}
                    onChange={(e) => setEmailSubject(e.target.value)}
                    style={{ width: "100%" }}
                  />
                </div>

                <div style={{ marginBottom: "20px" }}>
                  <label style={{ display: "block", marginBottom: "5px" }}>
                    Content:
                  </label>
                  <ReactQuill
                    value={emailContent}
                    onChange={setEmailContent}
                    theme="snow"
                    modules={{
                      toolbar: [
                        [{ header: [1, 2, 3, false] }],
                        ["bold", "italic", "underline", "strike"],
                        [{ color: [] }, { background: [] }],
                        [{ list: "ordered" }, { list: "bullet" }],
                        [{ align: [] }],
                        ["link"],
                        ["clean"],
                      ],
                    }}
                    style={{ height: "200px", marginBottom: "40px" }}
                  />
                </div>

                <div style={{ marginBottom: "20px" }}>
                  <label style={{ display: "block", marginBottom: "5px" }}>
                    Email Signature:
                  </label>
                  <ReactQuill
                    value={emailSignature}
                    onChange={(content) => {
                      setEmailSignature(content);
                      axios
                        .post(
                          "/api/admin/email-signature",
                          { signature: content },
                          {
                            headers: {
                              Authorization: `Bearer ${localStorage.getItem(
                                "authToken"
                              )}`,
                            },
                          }
                        )
                        .catch((error) =>
                          console.error("Error saving signature:", error)
                        );
                    }}
                    theme="snow"
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline"],
                        [{ color: [] }],
                        ["link"],
                        ["clean"],
                      ],
                    }}
                    style={{ height: "100px", marginBottom: "20px" }}
                  />
                </div>

                <div style={{ marginBottom: "20px" }}>
                  <label style={{ display: "block", marginBottom: "5px" }}>
                    Schedule Send (Optional):
                  </label>
                  <Input
                    type="datetime-local"
                    value={scheduledDateTime}
                    onChange={(e) => setScheduledDateTime(e.target.value)}
                    min={new Date().toISOString().slice(0, 16)}
                  />
                </div>

                {scheduledEmails.length > 0 && (
                  <div style={{ marginBottom: "20px" }}>
                    <h4>Scheduled Emails:</h4>
                    <Table>
                      <thead>
                        <tr>
                          <Th>Subject</Th>
                          <Th>Scheduled For</Th>
                          <Th>Status</Th>
                          <Th>Actions</Th>
                        </tr>
                      </thead>
                      <tbody>
                        {scheduledEmails.map((email) => (
                          <tr key={email._id}>
                            <Td>{email.subject}</Td>
                            <Td>
                              {new Date(email.scheduledAt).toLocaleString()}
                            </Td>
                            <Td>{email.status}</Td>
                            <Td>
                              <Button
                                onClick={() => {
                                  axios
                                    .delete(
                                      `/api/admin/scheduled-emails/${email._id}`,
                                      {
                                        headers: {
                                          Authorization: `Bearer ${localStorage.getItem(
                                            "authToken"
                                          )}`,
                                        },
                                      }
                                    )
                                    .then(() => {
                                      setScheduledEmails(
                                        scheduledEmails.filter(
                                          (e) => e._id !== email._id
                                        )
                                      );
                                      setSuccessMessage(
                                        "Scheduled email deleted successfully"
                                      );
                                    })
                                    .catch((error) =>
                                      setError(
                                        "Failed to delete scheduled email"
                                      )
                                    );
                                }}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </Button>
                            </Td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}

                <div style={{ marginBottom: "20px" }}>
                  <label style={{ display: "block", marginBottom: "5px" }}>
                    Attachments:
                  </label>
                  <input
                    type="file"
                    multiple
                    onChange={(e) => {
                      const files = Array.from(e.target.files);
                      setAttachments([...attachments, ...files]);
                    }}
                  />
                </div>

                {attachments.length > 0 && (
                  <div style={{ marginBottom: "20px" }}>
                    <h4>Attached Files:</h4>
                    {attachments.map((file, index) => (
                      <AttachmentPreview key={index}>
                        <span>{file.name}</span>
                        <Button
                          onClick={() =>
                            setAttachments(
                              attachments.filter((_, i) => i !== index)
                            )
                          }
                          style={{ padding: "4px 8px" }}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </AttachmentPreview>
                    ))}
                  </div>
                )}

                <div
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "10px",
                  }}
                >
                  <Button
                    onClick={() => {
                      setEmailModal(false);
                      setEmailContent("");
                      setEmailSubject("");
                      setAttachments([]);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={async () => {
                      setSending(true);
                      try {
                        const formData = new FormData();
                        formData.append("subject", emailSubject);
                        formData.append(
                          "htmlContent",
                          emailContent +
                            (emailSignature
                              ? `<div class="signature">${emailSignature}</div>`
                              : "")
                        );
                        if (scheduledDateTime) {
                          formData.append(
                            "scheduledAt",
                            new Date(scheduledDateTime).toISOString()
                          );
                        }
                        formData.append(
                          "userIds",
                          JSON.stringify(selectedUsers)
                        );
                        attachments.forEach((file) => {
                          formData.append("attachments", file);
                        });

                        await axios.post(
                          "/api/admin/send-bulk-email",
                          formData,
                          {
                            headers: {
                              Authorization: `Bearer ${localStorage.getItem(
                                "authToken"
                              )}`,
                              "Content-Type": "multipart/form-data",
                            },
                          }
                        );

                        setSuccessMessage("Bulk email sent successfully");
                        setEmailModal(false);
                        setEmailContent("");
                        setEmailSubject("");
                        setScheduledDateTime("");
                        setAttachments([]);
                        // Don't clear signature as it's likely to be reused
                      } catch (error) {
                        setError("Failed to send bulk email: " + error.message);
                      } finally {
                        setSending(false);
                      }
                    }}
                    disabled={
                      sending ||
                      !emailSubject ||
                      !emailContent ||
                      selectedUsers.length === 0
                    }
                  >
                    {sending ? (
                      <>
                        <FontAwesomeIcon icon={faSpinner} spin /> Sending...
                      </>
                    ) : (
                      "Send Email"
                    )}
                  </Button>
                </div>

                <Button
                  onClick={() => setEmailModal(false)}
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    padding: "5px 10px",
                    backgroundColor: "transparent",
                    color: "#666",
                  }}
                >
                  ×
                </Button>
              </div>
            </EmailModal>
          )}
        </>
      )}

      {activeTab === "scheduledEmails" && (
        <>
          <h2>Scheduled Emails</h2>
          <ButtonGroup>
            <Button
              onClick={() => {
                setSelectedUsers([]);
                setEmailModal(true);
                setEmailContent("");
                setEmailSubject("");
                setScheduledDateTime("");
                setAttachments([]);
              }}
            >
              <FontAwesomeIcon icon={faEnvelope} /> Create New Scheduled Email
            </Button>
          </ButtonGroup>

          {scheduledEmails.length > 0 ? (
            <Table>
              <thead>
                <tr>
                  <Th>Subject</Th>
                  <Th>Preview</Th>
                  <Th>Scheduled For</Th>
                  <Th>Recipients</Th>
                  <Th>Status</Th>
                  <Th>Actions</Th>
                </tr>
              </thead>
              <tbody>
                {scheduledEmails.map((email) => (
                  <tr key={email._id}>
                    <Td>{email.subject}</Td>
                    <Td>
                      <Button
                        onClick={() => {
                          setEditingEmail(email);
                          setEmailModal(true);
                          setEmailContent(email.htmlContent);
                          setEmailSubject(email.subject);
                          setScheduledDateTime(
                            new Date(email.scheduledAt)
                              .toISOString()
                              .slice(0, 16)
                          );
                          setSelectedUsers(email.userIds);
                        }}
                      >
                        Preview/Edit
                      </Button>
                    </Td>
                    <Td>
                      <Input
                        type="datetime-local"
                        value={new Date(email.scheduledAt)
                          .toISOString()
                          .slice(0, 16)}
                        onChange={(e) => {
                          const newDate = e.target.value;
                          axios
                            .post(
                              `/api/admin/scheduled-emails/${email._id}/update-schedule`,
                              {
                                scheduledAt: new Date(newDate).toISOString(),
                              },
                              {
                                headers: {
                                  Authorization: `Bearer ${localStorage.getItem(
                                    "authToken"
                                  )}`,
                                },
                              }
                            )
                            .then(() => {
                              setScheduledEmails(
                                scheduledEmails.map((e) =>
                                  e._id === email._id
                                    ? {
                                        ...e,
                                        scheduledAt: new Date(
                                          newDate
                                        ).toISOString(),
                                      }
                                    : e
                                )
                              );
                              setSuccessMessage(
                                "Schedule updated successfully"
                              );
                            })
                            .catch((error) =>
                              setError("Failed to update schedule")
                            );
                        }}
                        min={new Date().toISOString().slice(0, 16)}
                      />
                    </Td>
                    <Td>
                      <div style={{ maxHeight: "100px", overflowY: "auto" }}>
                        {email.userIds.length} recipients
                        <Button
                          onClick={() => {
                            setEditingEmail(email);
                            setRecipientModal(true);
                            setSelectedUsers(email.userIds);
                          }}
                          style={{ marginLeft: "10px" }}
                        >
                          Edit Recipients
                        </Button>
                      </div>
                    </Td>
                    <Td>{email.status}</Td>
                    <Td>
                      <ButtonGroup>
                        <Button
                          onClick={() => {
                            if (
                              window.confirm(
                                "Are you sure you want to delete this scheduled email?"
                              )
                            ) {
                              axios
                                .delete(
                                  `/api/admin/scheduled-emails/${email._id}`,
                                  {
                                    headers: {
                                      Authorization: `Bearer ${localStorage.getItem(
                                        "authToken"
                                      )}`,
                                    },
                                  }
                                )
                                .then(() => {
                                  setScheduledEmails(
                                    scheduledEmails.filter(
                                      (e) => e._id !== email._id
                                    )
                                  );
                                  setSuccessMessage(
                                    "Scheduled email deleted successfully"
                                  );
                                })
                                .catch((error) =>
                                  setError("Failed to delete scheduled email")
                                );
                            }
                          }}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </ButtonGroup>
                    </Td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No scheduled emails found.</p>
          )}
        </>
      )}

      {activeTab === "deliveryProofs" && (
        <>
          <h2>Delivery Proofs by Seller</h2>
          {isLoading ? (
            <p>Loading delivery proofs...</p>
          ) : deliveryProofs.length > 0 ? (
            Object.entries(groupByEmail(deliveryProofs)).map(
              ([email, data]) => (
                <SellerSection key={email}>
                  <SellerHeader onClick={() => toggleSeller(email)}>
                    <div>
                      <strong>{email}</strong> - {data.seller.name}
                    </div>
                    <ExpandIcon>
                      <FontAwesomeIcon
                        icon={
                          expandedSellers.has(email)
                            ? faChevronUp
                            : faChevronDown
                        }
                      />
                    </ExpandIcon>
                  </SellerHeader>

                  {expandedSellers.has(email) &&
                    Object.entries(data.products).map(
                      ([productId, product]) => (
                        <ProductSection key={productId}>
                          <ProductHeader
                            onClick={() => toggleProduct(productId)}
                          >
                            <div>{product.productName}</div>
                            <ExpandIcon>
                              <FontAwesomeIcon
                                icon={
                                  expandedProducts.has(productId)
                                    ? faChevronUp
                                    : faChevronDown
                                }
                              />
                            </ExpandIcon>
                          </ProductHeader>

                          {expandedProducts.has(productId) &&
                            product.proofs.map((proof, index) => (
                              <div
                                key={index}
                                style={{
                                  padding: "15px",
                                  backgroundColor: "white",
                                }}
                              >
                                <OrderDetails>
                                  <p>
                                    <strong>Status:</strong> {proof.orderStatus}
                                  </p>
                                  <p>
                                    <strong>Buyer Name:</strong>{" "}
                                    {proof.buyerName}
                                  </p>
                                  <p>
                                    <strong>Buyer Mobile:</strong>{" "}
                                    {proof.buyerMobile}
                                  </p>
                                  <p>
                                    <strong>Quantity:</strong> {proof.quantity}
                                  </p>
                                  <p>
                                    <strong>Total Price:</strong> RM
                                    {proof.totalPrice.toFixed(2)}
                                  </p>
                                  <p>
                                    <strong>Order Date:</strong>{" "}
                                    {new Date(proof.orderDate).toLocaleString()}
                                  </p>
                                  <p>
                                    <strong>Proof Uploaded:</strong>{" "}
                                    {new Date(
                                      proof.deliveryProof.uploadedAt
                                    ).toLocaleString()}
                                  </p>
                                </OrderDetails>

                                <ShowPhotoButton
                                  onClick={() =>
                                    togglePhoto(`${productId}-${index}`)
                                  }
                                >
                                  {shownPhotos.has(`${productId}-${index}`)
                                    ? "Hide Photo"
                                    : "Show Photo"}
                                </ShowPhotoButton>

                                {shownPhotos.has(`${productId}-${index}`) && (
                                  <DeliveryProofImage
                                    src={proof.deliveryProof.photo}
                                    alt="Delivery Proof"
                                    onClick={() =>
                                      window.open(
                                        proof.deliveryProof.photo,
                                        "_blank"
                                      )
                                    }
                                    style={{
                                      cursor: "pointer",
                                      marginTop: "10px",
                                    }}
                                  />
                                )}
                              </div>
                            ))}
                        </ProductSection>
                      )
                    )}
                </SellerSection>
              )
            )
          ) : (
            <p>No delivery proofs found.</p>
          )}
        </>
      )}

      {recipientModal && editingEmail && (
        <EmailModal>
          <div style={{ position: "relative", padding: "20px" }}>
            <h3 style={{ marginBottom: "20px" }}>Manage Recipients</h3>

            <div style={{ marginBottom: "20px" }}>
              <div className="relative flex-1 max-w-md mb-4">
                <Search
                  className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                  size={20}
                />
                <Input
                  type="text"
                  placeholder="Search users..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="pl-10"
                />
              </div>

              <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                <Table>
                  <thead>
                    <tr>
                      <Th>
                        <input
                          type="checkbox"
                          checked={users.every((user) =>
                            editingEmail.userIds.includes(user._id)
                          )}
                          onChange={(e) => {
                            const newRecipients = e.target.checked
                              ? users.map((user) => user._id)
                              : [];

                            axios
                              .post(
                                `/api/admin/scheduled-emails/${editingEmail._id}/recipients`,
                                {
                                  userIds: newRecipients,
                                },
                                {
                                  headers: {
                                    Authorization: `Bearer ${localStorage.getItem(
                                      "authToken"
                                    )}`,
                                  },
                                }
                              )
                              .then(() => {
                                setScheduledEmails(
                                  scheduledEmails.map((email) =>
                                    email._id === editingEmail._id
                                      ? { ...email, userIds: newRecipients }
                                      : email
                                  )
                                );
                                setEditingEmail({
                                  ...editingEmail,
                                  userIds: newRecipients,
                                });
                                setSuccessMessage(
                                  "Recipients updated successfully"
                                );
                              })
                              .catch((error) =>
                                setError("Failed to update recipients")
                              );
                          }}
                        />
                      </Th>
                      <Th>Email</Th>
                      <Th>Name</Th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredUsers.map((user) => (
                      <tr key={user._id}>
                        <Td>
                          <input
                            type="checkbox"
                            checked={editingEmail.userIds.includes(user._id)}
                            onChange={(e) => {
                              const newRecipients = e.target.checked
                                ? [...editingEmail.userIds, user._id]
                                : editingEmail.userIds.filter(
                                    (id) => id !== user._id
                                  );

                              axios
                                .post(
                                  `/api/admin/scheduled-emails/${editingEmail._id}/recipients`,
                                  {
                                    userIds: newRecipients,
                                  },
                                  {
                                    headers: {
                                      Authorization: `Bearer ${localStorage.getItem(
                                        "authToken"
                                      )}`,
                                    },
                                  }
                                )
                                .then(() => {
                                  setScheduledEmails(
                                    scheduledEmails.map((email) =>
                                      email._id === editingEmail._id
                                        ? { ...email, userIds: newRecipients }
                                        : email
                                    )
                                  );
                                  setEditingEmail({
                                    ...editingEmail,
                                    userIds: newRecipients,
                                  });
                                  setSuccessMessage(
                                    "Recipients updated successfully"
                                  );
                                })
                                .catch((error) =>
                                  setError("Failed to update recipients")
                                );
                            }}
                          />
                        </Td>
                        <Td>{user.email}</Td>
                        <Td>{user.name || "-"}</Td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>

            <Button
              onClick={() => {
                setRecipientModal(false);
                setEditingEmail(null);
              }}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                padding: "5px 10px",
                backgroundColor: "transparent",
                color: "#666",
              }}
            >
              ×
            </Button>
          </div>
        </EmailModal>
      )}
    </Container>
  );
}

export default AdminDashboard;
